import React from 'react';

interface BillingCreditCardProps {
  firstName: string;
  lastName: string;
  cardType: string;
  lastFour: string;
}
const BillingCreditCard = ({ firstName, lastName, cardType, lastFour }: BillingCreditCardProps) => (
  <div className="spc-info-details">
    <div className="spc-info-details-wrap">
      <div>
        {firstName} {lastName}
      </div>
      <div>
        {cardType} ending in {lastFour}
      </div>
    </div>
  </div>
);

BillingCreditCard.displayName = 'BillingCreditCard';

export default BillingCreditCard;
