import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import OptIn from './OptIn';

const propTypes = {
  gdprOptInShow: PropTypes.bool,
};
const defaultProps = {
  gdprOptInShow: true,
};

class LinkToPopup extends Component {
  constructor(props) {
    super(props);
    // Create the element when the component is instantiated
    this.startingEl = document.createElement('div');
    this.startingEl.id = 'js-gdpr-optin';
    document.body.appendChild(this.startingEl);
  }

  componentWillUnmount() {
    if (this.startingEl && this.startingEl.parentNode) {
      this.startingEl.parentNode.removeChild(this.startingEl);
    }
  }

  render() {
    const { gdprOptInShow } = this.props;
    return gdprOptInShow ? createPortal(createElement(OptIn, null), this.startingEl) : null;
  }
}

LinkToPopup.propTypes = propTypes;
LinkToPopup.defaultProps = defaultProps;
export default LinkToPopup;
