import React from 'react';
import withArrayDisplay from '../../common/withArrayDisplay';
import paypal from '../../../images/paypal.png';
import applepay from '../../../images/applepay.png';
import venmo from '../../../images/venmo.png';
import AddressDisplay from '../../common/AddressDisplay';
import BillingCreditCard from '../BillingCreditCard';
import { BILLING_METHODS } from '../../../constants';

interface Props {
  method: string;
  firstName?: string;
  lastName?: string;
  cardType?: string;
  lastFour?: string;
  company?: string;
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
}

const SavedPayment = (props: Props) => {
  const {
    firstName = '',
    lastName = '',
    cardType = '',
    lastFour = '',
    address = '',
    city = '',
    state = '',
    postalCode = '',
    country = '',
    method,
  } = props;
  if (method !== BILLING_METHODS.SAVED_PAYMENT) {
    return null;
  }
  return (
    <div>
      <div className="spc-info mod-billing">
        <BillingCreditCard {...{ firstName, lastName, cardType, lastFour }} />
      </div>
    </div>
  );
};

const CreditCard = (props: Props) => {
  const {
    firstName = '',
    lastName = '',
    cardType = '',
    lastFour = '',
    company = '',
    address = '',
    address2 = '',
    city = '',
    state = '',
    postalCode = '',
    country = '',
    method,
  } = props;

  return (
    method === BILLING_METHODS.CREDIT_CARD && (
      <div>
        <div className="spc-info mod-billing">
          <BillingCreditCard {...{ firstName, lastName, cardType, lastFour }} />
        </div>
      </div>
    )
  );
};

const Paypal = (props: Props) =>
  props.method === BILLING_METHODS.PAYPAL && (
    <div className="spc-info mod-billing">
      <div className="spc-info-details">
        <div className="spc-info-details-wrap">
          <div>
            <img src={paypal} alt="Paypal" width="75" />
          </div>
          <div>You have successfully linked your PayPal account.</div>
        </div>
      </div>
    </div>
  );

const ApplePay = (props: Props) =>
  props.method === BILLING_METHODS.APPLEPAY && (
    <div className="spc-info mod-billing">
      <div className="spc-info-details">
        <div className="spc-info-details-wrap">
          <div>
            <img src={applepay} alt="Apple Pay" height="30" />
          </div>
          <div>You have successfully linked your Apple Pay account.</div>
        </div>
      </div>
    </div>
  );

const Venmo = (props: Props) =>
  props.method === BILLING_METHODS.VENMO && (
    <div className="spc-info mod-billing">
      <div className="spc-info-details">
        <div className="spc-info-details-wrap">
          <div>
            <img src={venmo} alt="Venmo" height="25" />
          </div>
          <div>You have successfully linked your Venmo account.</div>
        </div>
      </div>
    </div>
  );

const PaymentMethodInfo = (props: Props) => {};
PaymentMethodInfo.componentArray = [SavedPayment, CreditCard, Paypal, ApplePay, Venmo];

export default withArrayDisplay(PaymentMethodInfo);
