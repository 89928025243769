/* globals fetch, Headers */

/*
  utilities for handling 23andMe store API in SPC
  TODO: with addition of redux, all API calls will be actions handled by a dispatcher.
 */

import { ERROR_MESSAGES, STEP } from '../constants';
import { API_URLS } from '../constantsv2';
import { URLS } from '../regions';
import { unpackLabelData } from './i18nFields';
import toSnake from './toSnake';
import toCamel from './toCamel';
import { identify } from './heapTracker';
import { get, post, del, put } from './baseApi';
import { REGION_CODE } from '.././regions';

const fetchStates = (
  country,
  callback = (data: GeoState[]) => data,
  showError = null,
): Promise<any> => {
  const url = `${URLS.FETCH_STATES}${country}/`;
  const onSuccess = data => {
    // eslint-disable-next-line no-param-reassign
    if (data.length && data[0].value === 'N/A') delete data[0];
    return callback(data);
  };
  const onError = () => {
    showError({
      title: 'Address Error',
      body: ERROR_MESSAGES.ADDRESS,
      button: 'edit address',
      element: 'address',
      step: STEP.ADDRESS,
    });
  };
  return get(url, onSuccess, onError);
};

const fetchLabels = (
  country,
  callback = (data: FieldInfo) => data,
  showError = null,
): Promise<any> => {
  const url = `${URLS.FETCH_LABELS}${country}/`;
  const onSuccess = data => callback(unpackLabelData(toCamel(data)));
  const onError = () => {
    showError({
      title: 'Address Error',
      body: ERROR_MESSAGES.ADDRESS,
      button: 'edit address',
      element: 'address',
      step: STEP.ADDRESS,
    });
  };
  return get(url, onSuccess, onError);
};

const fetchShippingMethods = (country, callback, showError) => {
  const url = `${URLS.FETCH_SHIPPING_METHODS}${country}/`;
  const onSuccess = data => callback(toCamel(data));
  const onError = () => {
    showError({
      title: 'Address Error',
      body: ERROR_MESSAGES.SHIP_METHOD,
      button: 'edit address',
      element: 'address',
      step: STEP.ADDRESS,
    });
  };
  return get(url, onSuccess, onError);
};

const postShippingAddress = (body, successCallback, errorCallback) => {
  const url = URLS.SHIPPING;
  const onSuccess = content => {
    content && identify(content.heap_id);
    return successCallback(toCamel(content));
  };
  return post(url, toSnake(body), onSuccess, errorCallback);
};

const newPostShipMethod = async body => {
  const url = URLS.SHIPMETHOD;
  const res = await post(url, toSnake(body));
  return toCamel(res);
};

function postValidation(body, successCallback, errorCallback = null) {
  return fetch(URLS.VALIDATION, {
    credentials: 'same-origin',
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(toSnake(body)),
  })
    .then(response => response.json())
    .then(resp => {
      const data: any = toCamel(resp);
      const { error } = data;
      if (error && errorCallback) return errorCallback(data);
      if (error) return this.handleSubmitError(data);
      return successCallback(data);
    })
    .catch(() => {
      if (errorCallback) return errorCallback();
      this.handleSubmitError();
    });
}

function removeSavedPayment(token, successCallback, errorCallback) {
  if (!token) return;
  const url = `/${REGION_CODE}${API_URLS.SAVE_PAYMENT}${token}`;
  const onSuccess = results => successCallback(toCamel(results));
  // eslint-disable-next-line consistent-return
  return del(url, onSuccess, errorCallback);
}

function addSavedPayment(
  body: {
    paymentMethodNonce: string;
    billingAddress?: Address;
    makeDefault?: boolean;
    billingAddressId?: string;
    method: string;
  },
  successCallback,
  errorCallback,
) {
  const url = `/${REGION_CODE}${API_URLS.SAVE_PAYMENT}`;
  const onSuccess = paymentMethodData => successCallback(toCamel(paymentMethodData));
  return post(url, toSnake(body), onSuccess, errorCallback);
}

function updateSavedPayment(
  body: {
    paymentMethodToken: string;
    paymentMethodNonce?: string;
    billingAddress?: Address;
    makeDefault?: boolean;
    billingAddressId?: string;
  },
  successCallback,
  errorCallback,
) {
  const url = `/${REGION_CODE}${API_URLS.SAVE_PAYMENT}${body.paymentMethodToken}/`;
  const onSuccess = paymentMethodData => successCallback(toCamel(paymentMethodData));
  return put(url, toSnake(body), onSuccess, errorCallback);
}

function saveThePayment(payload, onSuccess, onFail, token = null) {
  if (token) {
    payload['paymentMethodToken'] = token;
    updateSavedPayment(payload, onSuccess, onFail);
  } else {
    addSavedPayment(payload, onSuccess, onFail);
  }
}

function createSubscription(
  body: {
    paymentMethodToken: string;
    planId: string;
    profileId: string;
    orderId?: string;
    isHaKitBundle?: boolean;
    billingAddress?: object;
    isPayPalPayment?: boolean;
  },
  successCallback,
  errorCallback,
) {
  const onSuccess = resp => successCallback(toCamel(resp));
  const url = `/${REGION_CODE}${API_URLS.SUBSCRIPTION_PAYMENTS}`;
  return post(url, toSnake(body), onSuccess, errorCallback);
}

function createSubscriptionRenewal(
  body: {
    paymentMethodToken: string;
    planId: string;
    profileId: string;
    isPaypalPayment?: boolean;
  },
  successCallback,
  errorCallback,
) {
  const onSuccess = resp => successCallback(toCamel(resp));
  const url = `/${REGION_CODE}${API_URLS.RENEW_SUBSCRIPTION_PAYMENTS}`;
  return post(url, toSnake(body), onSuccess, errorCallback);
}

function cancelSubscription(subscriptionId, successCallback, errorCallBack) {
  const url = `/${REGION_CODE}${API_URLS.SUBSCRIPTION_PAYMENTS}${subscriptionId}`;
  // eslint-disable-next-line consistent-return
  return del(url, successCallback, errorCallBack);
}

function updateSubscriptionPayment(
  body: {
    paymentMethodToken: string;
    subscriptionId: string;
  },
  successCallback,
  errorCallback,
) {
  const url = `/${REGION_CODE}${API_URLS.SUBSCRIPTION_PAYMENTS}${body.subscriptionId}/`;
  return put(url, toSnake(body), successCallback, errorCallback);
}

function postSurveyResults(payload) {
  post(`${URLS.SURVEY}`, toSnake(payload));
}

function setOptInSubscriptionRenewal(body: { profileId: string; optInStatus: string }) {
  const url = `/${REGION_CODE}${API_URLS.SUBSCRIPTION_RENEWAL_OPTIN}`;
  return post(url, toSnake(body));
}

const handleHealthUpgradeUpdateHuman = (profile_id: string) => {
  const url = `/${REGION_CODE}${API_URLS.HEALTH_UPGRADE_UPDATE_HUMAN}`;
  return post(url, { profile_id });
};

export {
  fetchStates,
  fetchLabels,
  fetchShippingMethods,
  postShippingAddress,
  newPostShipMethod,
  postValidation,
  removeSavedPayment,
  addSavedPayment,
  updateSavedPayment,
  saveThePayment,
  createSubscription,
  createSubscriptionRenewal,
  cancelSubscription,
  postSurveyResults,
  setOptInSubscriptionRenewal,
  updateSubscriptionPayment,
  handleHealthUpgradeUpdateHuman,
};
