import React from 'react';
import initialState from './initialState';
import { REGION, HEAP_REGIONS } from './regions';
import { trackCohortData } from './utils/heapTracker';
import { baseReducer } from './reducers/baseReducer';
import { useReducerWithMiddleware } from './utils/storeUtils';
import { beforeMiddlewares, afterMiddlewares } from './middleware';

type StoreState = any; // Replace with your actual state type
type Dispatch = React.Dispatch<any>; // Replace with your actual action type
export const Store = React.createContext<
  { storeState: StoreState; dispatch: Dispatch } | undefined
>(undefined);

export function StoreProvider(props: { children: React.ReactNode }) {
  const [storeState, dispatch] = useReducerWithMiddleware(
    baseReducer,
    initialState,
    beforeMiddlewares,
    afterMiddlewares,
  );

  const value: { storeState: StoreState; dispatch: Dispatch } = { storeState, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

const cleanUrlParams = (excludeKeys: string[] = []) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  excludeKeys.forEach(key => params.delete(key));
  if (typeof window !== 'undefined') {
    window.history.replaceState(null, '', `${url.pathname}?${params}`);
  }
};

export function setupPage() {
  cleanUrlParams(['plan', 'sub_coupon', 'source']);
  // Send Optimizely cohort data to heap analytics
  if (HEAP_REGIONS.includes(REGION)) {
    trackCohortData();
  }
}
