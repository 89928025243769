import React, { useContext, useEffect, useState } from 'react';
import Address from '../components/Address';
import CookieBanner from '../components/CookieBanner';
import ErrorPopup from '../components/common/ErrorPopup';
import PriceModal from '../components/common/PriceModal';
import Footer from '../components/Footer';
import GdprPortal from '../components/Gdpr/indexPortal';
import Header from '../components/Header';
import Order from '../components/Order';
import OrderDetails from '../components/common/OrderDetails';
import Review from '../components/Review';
import Shipping from '../components/Shipping';
import Verification from '../components/Verification';
import Authentication from '../components/Authentication';
import { showError, updateStore } from '../utils/storeUtils';
import { ELEMENTS, ERROR_MESSAGES, STEP, NON_CC, MAGENTO_ERROR_KEYWORDS } from '../constants';
import { REGION, PRICE_MODAL_REGIONS, PRICE_MODAL_KIT_SKUS, URLS } from '../regions';
import { parseIsoCountryCode } from '../utils/i18nFields';
import { postShippingAddress, postValidation } from '../utils/storeApi';
import { Store } from '../pageSetup';
import { trackOrderSuccess as trackOrderSuccessForEmailCapture } from '../utils/captureEmail';
import { goNextStep } from '../actions/thunks';
import { guideFocusToElement } from '../utils/scroller';
import SpcBillingContainer from '../components/Billing/SpcBillingContainer';
import { EligibilityModals } from '../components/EligibilityModals/EligibilityModals';

const CheckoutContainer = () => {
  const { storeState, dispatch } = useContext(Store);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  const showPriceModal =
    PRICE_MODAL_REGIONS.includes(REGION) &&
    storeState.cart.kits.some(kit => PRICE_MODAL_KIT_SKUS.includes(kit.sku)) &&
    window.optimizelyShowPriceModal;

  useEffect(() => {
    if (storeState.nonCC) {
      handleNonCCFlow();
    }
  }, [storeState.nonCC]);

  const showShippingAddressChangeNeededError = (countryCode, data) => {
    updateStore(dispatch, { nonCC: false });
    showError(dispatch, {
      countryCode,
      body: data.error,
      button: 'edit shipping destination',
      element: 'address',
      method: storeState.paymentMethod,
      step: STEP.ADDRESS,
      title: `Notice: ${NON_CC[storeState.paymentMethod]} address change needed`,
    });
  };

  const showAddressError = () => {
    showError(dispatch, {
      title: 'Address Error',
      body: ERROR_MESSAGES.ADDRESS,
      button: 'edit address',
      element: 'address',
      step: STEP.ADDRESS,
    });
  };

  const showEmptyCartError = (body = ERROR_MESSAGES.EMPTY_CART) => {
    showError(dispatch, {
      title: 'Error: Empty Cart',
      body,
      button: 'return to order page',
      startOver: true,
    });
  };

  const handleSubmitAddressError = (data = {}) => {
    if (data.rejectCart) {
      showEmptyCartError();
    } else if (data.wrongShippingCountry && storeState.nonCC) {
      const countryCode = storeState.address.country;
      const addressData = { ...storeState.address, country: parseIsoCountryCode(REGION) };
      showShippingAddressChangeNeededError(countryCode, data);
      updateStore(dispatch, { address: addressData });
    } else {
      showAddressError();
    }
  };

  const handleSubmitError = (data = {}) => {
    if (data.rejectCart) {
      showEmptyCartError(data.error);
    } else {
      data.body = MAGENTO_ERROR_KEYWORDS.some(keyword =>
        data.error?.toLowerCase().includes(keyword),
      )
        ? ERROR_MESSAGES.ADDRESS
        : ERROR_MESSAGES.BILLING_PROCESSING;
      showError(dispatch, data);
      updateStore(dispatch, { editableClass: '' });
      guideFocusToElement('js-error-modal');
    }
  };

  const submit = async event => {
    event.preventDefault();
    setIsProcessing(true);
    updateStore(dispatch, { nonCC: false, editableClass: ' mod-hide', isProcessing: true });

    const { paymentMethod, billing, address } = storeState;
    const billingInfo = { ...billing, method: paymentMethod };
    const deviceData = window.deviceData;
    const sanitizedAddress = { ...address, email: address.email.trim().toLowerCase() };

    await postValidation(
      { address: sanitizedAddress, billing: billingInfo, deviceData, paymentMethod },
      data => {
        trackOrderSuccess(data.receiptId);
        redirectToReceiptPage(data.receiptId);
      },
      handleSubmitError,
    );
  };

  const redirectToReceiptPage = receiptId => {
    if (receiptId) window.location.href = `${URLS.RECEIPT}${receiptId}`;
  };

  const trackOrderSuccess = receiptId => {
    if (storeState.gdprAvailable && receiptId) {
      trackOrderSuccessForEmailCapture({
        kits: storeState.cart.kits,
        email: storeState.address.email,
      });
    }
  };

  const handleNonCCFlow = () => {
    postShippingAddress(
      storeState.address,
      data => {
        if (data.error) {
          handleSubmitAddressError(data);
          return;
        }
        updateStore(dispatch, { isProcessing: false, loading: false });
        dispatch(goNextStep(`#${ELEMENTS.review}`, -250, true));
      },
      handleSubmitAddressError,
    );
  };

  return (
    <div className={`spc${loading ? ' mod-spinner' : ''}`}>
      <div className={`spc-container${loading ? ' spc-hide' : ''}`}>
        <main role="main">
          <Header logoUrl={storeState.pubUrl} />
          <CookieBanner />
          <Authentication />
          <Address
            address={storeState.address}
            showEmptyCartError={showEmptyCartError}
            showError={showError}
          />
          <Verification
            address={storeState.address}
            verifiedAddress={storeState.verifiedAddress}
            step={storeState.step}
          />
          <Shipping street={storeState.address.address} geoState={storeState.address.state} />
          <SpcBillingContainer />
          <Review
            country={storeState.address.country}
            submit={submit}
            cart={storeState.cart}
            disabledBtnClass={isProcessing ? ' mod-disabled' : ''}
          />
          {storeState.showOrderDetails && <OrderDetails />}
        </main>
        <Footer />
        <EligibilityModals />
        <div className="spc-error-wrap">
          <ErrorPopup />
        </div>
        <div className="spc-error-wrap">{showPriceModal && <PriceModal />}</div>
      </div>
      {storeState.showGDPR && <GdprPortal />}
    </div>
  );
};

export default CheckoutContainer;
