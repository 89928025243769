import { Reducer, useReducer, useContext, useRef, useEffect } from 'react';
import { Store } from '../pageSetup';

export function updateStore(dispatch: Dispatch, data: Partial<StoreState>, callback = null) {
  dispatch({
    type: 'UPDATE_VALUE',
    data,
  });
  setImmediate(callback);
}

export function showError(dispatch: Dispatch, data: ErrorType) {
  dispatch({
    type: 'SHOW_ERROR',
    data,
  });
}

export type UseStoreCallback = (data: Partial<StoreState>, callback?: () => void) => void;

export const useStoreContext = () => {
  const { storeState, dispatch }: { storeState: StoreState; dispatch: Dispatch } = useContext(
    Store,
  );
  return { storeState, dispatch };
};

export const useStore = () => {
  const { storeState, dispatch } = useContext(Store);
  const returned: [StoreState, UseStoreCallback] = [
    storeState,
    (data, callback) => updateStore(dispatch, data, callback),
  ];
  return returned;
};

export const useReducerWithMiddleware = (
  reducer: Reducer<StoreState, Action>,
  initialState,
  middlewareFns,
  afterwareFns,
): [StoreState, Dispatch] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const aRef: any = useRef();

  const dispatchWithMiddleware = action => {
    for (const middlewareFn of middlewareFns) {
      middlewareFn(action, state, dispatch);
    }
    if (typeof action === 'function') {
      return action(state, dispatchWithMiddleware);
    } else {
      aRef.current = aRef.current || {};
      aRef.current.actions = aRef.current.actions || [];
      aRef.current.actions.push(action);
      dispatch(action);
    }
  };

  useEffect(() => {
    if (!aRef.current || !aRef.current.actions || !aRef.current.actions.length) return;
    aRef.current.actions.map(action => {
      afterwareFns.forEach(afterwareFn => afterwareFn(action, state, dispatch));
      aRef.current.actions = aRef.current.actions.slice(1);
    });
  }, [afterwareFns, state]);

  return [state, dispatchWithMiddleware];
};
