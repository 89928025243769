import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Checkout from './containers/Checkout';
import LabsCheckoutPage from './components/LabsCheckout/LabsCheckoutPage';
import SavedPaymentInfo from './containers/SavedPaymentInfo';
import SubscriptionPaymentPage from './containers/SubscriptionPaymentPage';
import SubscriptionReceipt from './containers/SubscriptionReceipt';
import SubscriptionRenewalOptIn from './containers/SubscriptionRenewalOptIn/SubscriptionRenewalOptIn';
import { PERMITTED_REGIONS } from './regions';

export const ROUTE_URLS = {
  CHECKOUT: 'checkout',
  PLATFORM_CHECKOUT: 'platform/checkout',
  BOOK_PAYMENT: 'book/payment',
  SAVED_PAYMENTS: 'saved-payments',
  SUBSCRIPTION_PAYMENT: 'subscription/payment',
  UPGRADE_SUBSCRIPTION_PAYMENT: 'upgrade-subscription/payment',
  RENEW_SUBSCRIPTION_PAYMENT: 'renew-subscription/payment',
  SUBSCRIPTION_RECEIPT: 'subscription/receipt',
  RENEWAL_OPT_IN: 'renewal-opt-in',
  TOTAL_HEALTH_CHECKOUT: 'total-health/checkout',
};

const Routes = () => {
  const checkoutPaths = [
    `/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.CHECKOUT}`,
    `/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.PLATFORM_CHECKOUT}`,
    `/${PERMITTED_REGIONS.US}/${ROUTE_URLS.BOOK_PAYMENT}`,
    `/${PERMITTED_REGIONS.US}/${ROUTE_URLS.TOTAL_HEALTH_CHECKOUT}`,
  ];
  return (
    <Router>
      <Route path={checkoutPaths} component={Checkout} />
      <Route strict path={`/${PERMITTED_REGIONS.ALL}/labs/`} component={LabsCheckoutPage} />
      <Route
        path={`/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.SAVED_PAYMENTS}`}
        component={SavedPaymentInfo}
      />
      <Route
        path={`/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.SUBSCRIPTION_PAYMENT}`}
        component={SubscriptionPaymentPage}
      />
      <Route
        path={`/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.UPGRADE_SUBSCRIPTION_PAYMENT}`}
        component={SubscriptionPaymentPage}
      />
      <Route
        path={`/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.RENEW_SUBSCRIPTION_PAYMENT}`}
        component={SubscriptionPaymentPage}
      />
      <Route
        path={`/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.SUBSCRIPTION_RECEIPT}`}
        component={SubscriptionReceipt}
      />
      <Route
        path={`/${PERMITTED_REGIONS.ALL}/${ROUTE_URLS.RENEWAL_OPT_IN}`}
        component={SubscriptionRenewalOptIn}
      />
    </Router>
  );
};

export default Routes;
