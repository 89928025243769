import React from 'react';
import AddressDisplay from '../common/AddressDisplay';
import { STEP } from '../../constantsv2';
import { useStoreContext } from '../../utils/storeUtils';
import { goToStep } from '../../actions/thunks';
interface Props {
  address: Address;
}

const AddressInfo = (props: Props) => {
  const { storeState, dispatch } = useStoreContext();
  const { email, phone } = props.address;

  const editableClass =
    storeState.paymentMethod === 'applepay' ? ' mod-hide' : storeState.editableClass;

  return (
    <div className="spc-wrap">
      <div className="spc-align no-pad-bottom">
        <h2 className="spc-align-h2 spc-count" data-stor-id="spc-address-header">
          Shipping Address
        </h2>
        <button
          className={`spc-button-edit${editableClass}`}
          onClick={e => {
            e.stopPropagation();
            dispatch(goToStep(STEP.ADDRESS));
          }}
          type="button"
        >
          Edit
        </button>
      </div>
      <div className="spc-info">
        <AddressDisplay
          firstName={props.address.firstName}
          lastName={props.address.lastName}
          company={props.address.company}
          address={props.address.address}
          address2={props.address.address2}
          city={props.address.city}
          state={props.address.state}
          postalCode={props.address.postalCode}
          country={props.address.country}
        />
      </div>
      <div className="spc-info" style={{ paddingTop: '0' }}>
        <div className="spc-info-details">
          <img alt="" src="/static/img/icons/ic_email.svg" width="24" height="26" />
          <div className="spc-info-details-wrap">
            <div>{email}</div>
            <div>{phone}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddressInfo.displayName = 'AddressInfo';

export default AddressInfo;
