/* globals window */
import * as React from 'react';
import OrderKit from './OrderKit';
import { URLS } from '../../regions';
import { useStore } from '../../utils/storeUtils';

interface Props {
  linksAndPrepaidBundleMsg: string;
  prepaidBundleMsg: string;
}

const Order = (props: Props) => {
  const [state] = useStore();
  const { cart, isBookPurchase } = state;
  const linksAndPrepaidBundleMsg = props.linksAndPrepaidBundleMsg;
  let utilSavings = null;
  if (
    cart.totals.itemsDiscount !== 0 &&
    !(state.hideUkPromoPricing || state.hasDiscountedPrepaidSubscription)
  ) {
    utilSavings = (
      <div className="spc-order-total-text">
        <div>You saved</div>
        <div>{cart.totals.displayItemsDiscount}</div>
      </div>
    );
  }
  const giftWrapCount = cart.kits.reduce((count, kit) => (kit.giftWrap ? count + 1 : count), 0);
  const orderKits = cart.kits.map(kit => (
    <OrderKit key={kit.kitId} {...kit} prepaidBundleMsg={props.prepaidBundleMsg} />
  ));

  const serviceOrProduct = isBookPurchase ? 'PRODUCT' : 'SERVICE';
  const isReadOnlyCart = state.isChipUpgrade || state.hasTotalHealthUpgrade || state.isBookPurchase;

  return (
    <div>
      <div>
        <div className="spc-order"></div>
        <div className="spc-order">
          <div className="spc-order-text" role="table">
            {orderKits}
            {!!giftWrapCount && (
              <>
                <div className="spc-order-price">
                  <div className="spc-order-price-name">
                    Gift or privacy option ({giftWrapCount})
                  </div>
                  <div className="spc-order-price-text mod-discount">FREE</div>
                </div>
                <button
                  className="spc-order-button-giftwrap"
                  onClick={e => {
                    e.stopPropagation();
                    window.location.href = URLS.GIFTWRAP;
                  }}
                  type="button"
                >
                  View selected gift options
                </button>
              </>
            )}
          </div>
          {linksAndPrepaidBundleMsg}
        </div>
      </div>
    </div>
  );
};

Order.displayName = 'Order';
export default Order;
