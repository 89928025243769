import React from 'react';

export interface AddressDisplayProps {
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  title?: string;
}

const AddressDisplay = ({
  firstName,
  lastName,
  company,
  address,
  address2,
  city,
  state,
  postalCode,
  country,
}: AddressDisplayProps) => (
  <div className="spc-info-details">
    <img alt="" src="/static/img/icons/ic_mehome.svg" width="24" height="26" />
    <div className="spc-info-details-wrap">
      <div>
        {firstName} {lastName}
      </div>
      <div>{company}</div>
      <div>
        {address} {address2}
      </div>
      <div>
        {city}, {state} {postalCode}, {country}
      </div>
    </div>
  </div>
);

AddressDisplay.displayName = 'AddressDisplay';

export default AddressDisplay;
