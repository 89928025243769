import React from 'react';
import BillingAddressInfo from './BillingAddressInfo';
import BillingAddressOption from './BillingAddressOption';
import AddressFieldsGroup from '../common/intl/AddressFieldsGroup/AddressFieldsGroup';
import { BILLING_ADDRESS } from '../../constants';
import handleKeyDown from '../../utils/handleKeyDown';

export interface BillingAddressProps {
  addressSource: number;
  clearErr?: (e: string) => void;
  countries: Country[];
  customAddress?: Address;
  disableCountrySelector?: boolean;
  errors?: FormError[];
  fieldInfo?: any;
  handleFieldChange?: (e) => void;
  method?: BillingMethods;
  savedPayment: SavedPayment;
  savedPaymentInfoClass: string;
  shippingAddress?: Address;
  states: GeoState[];
  switchToCustomAddress?: (addressType: string) => void;
}

const BillingAddress = ({
  addressSource,
  clearErr = () => {},
  countries,
  customAddress = {
    company: '',
    address: '',
  },
  disableCountrySelector = false,
  errors = [],
  fieldInfo = [],
  handleFieldChange = () => {},
  method = 'credit_card',
  savedPayment,
  savedPaymentInfoClass = '',
  shippingAddress = {},
  states,
  switchToCustomAddress,
}: BillingAddressProps) => {
  const displayMethods: Array<BillingMethods> = ['credit_card', 'saved_payment'];

  const billingAddressVisibility = displayMethods.includes(method) ? '' : ' mod-hide-cc mod-hidden';

  const customAddressMethods = [BILLING_ADDRESS.CUSTOM, BILLING_ADDRESS.SAVED_CUSTOM];
  const useCustomAddress = customAddressMethods.includes(addressSource);

  const customAddressClass = useCustomAddress ? '' : ' mod-hide-new';

  let displayAddress = shippingAddress;
  if (useCustomAddress) {
    displayAddress = customAddress;
  } else if (addressSource === BILLING_ADDRESS.SAVED) {
    // savedPayment consists of both payment and billing address flattened together
    displayAddress = savedPayment;
  }

  const AddressFields = AddressFieldsGroup.enint;
  return (
    <div className={`spc-billing-cc-address${billingAddressVisibility}${savedPaymentInfoClass}`}>
      <div className="spc-title">
        {!savedPaymentInfoClass && (
          <>
            <h3 className="spc-title-h3">Billing Address</h3>
            <BillingAddressOption {...{ addressSource, switchToCustomAddress, method }} />
          </>
        )}
      </div>
      {!savedPaymentInfoClass && (
        <div className={`spc-billing-address-old${customAddressClass}`}>
          <BillingAddressInfo {...displayAddress} />
        </div>
      )}
      <div
        className={`spc-billing-address-new${customAddressClass}`}
        role="group"
        aria-describedby="billing-address-details"
      >
        <div id="billing-address-details" className="spc-aria-description">
          Billing address details
        </div>
        <div
          id="js-spc-new-billing-address"
          className="spc-billing-address"
          onKeyPress={handleKeyDown}
          role="form"
        >
          <AddressFields
            address={customAddress}
            elemClass="spc-input-wrap mod-billing"
            forShipping={false}
            forPayment={!!savedPaymentInfoClass}
            handleChange={e => {
              handleFieldChange(e);
              clearErr(e.target.name);
            }}
            {...{
              countries,
              disableCountrySelector,
              states,
              errors,
              fieldInfo,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingAddress;
