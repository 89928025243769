export const GDPR_COOKIE = 'gdpr_cookies_notice';
export const GDPR_VISITED_COOKIE = 'gdpr_visited';
export const GDPR_LINKS_ATTRIBUTE = 'data-gdpr-link';
export const REQUIRED_COOKIES = [
  'AWSALB',
  '__cfduid',
  'uuid',
  'sessionid',
  'cart_count',
  'cookies_notice',
  'cookies_notice_reset',
  'csrftoken',
  'gdpr_cookies_notice',
  'selected_country',
  'uuid',
  '_help_center_session',
  '_zendesk_session',
  '_zendesk_shared_session',
  'gh_src',
  'gdpr_visited',
  'profile',
  'notice_banner',
];

// For backward compatibility with code using require()
export default {
  GDPR_COOKIE,
  GDPR_VISITED_COOKIE,
  GDPR_LINKS_ATTRIBUTE,
  REQUIRED_COOKIES,
};
