import validator from 'validator';
const { isEmail } = validator;

interface Validator {
  email?: RegExp;
  alpha?: RegExp;
  notJustWhiteSpace?: RegExp;
}

/**
 * Regular expression to match strings containing only valid phone number characters, including periods.
 *
 * @constant {RegExp} validPhoneCharactersRegex
 *
 * Explanation of the regex pattern: /^[\d+\-().  ]+$/
 *
 * ^        - Asserts the start of the string
 * [...]    - Defines a character set (any single character in the set will match)
 * \d       - Matches any digit (0-9)
 * +        - Matches the plus sign (for international numbers)
 * \-       - Matches the hyphen (escaped with \ because - has special meaning in regex)
 * (        - Matches an opening parenthesis
 * )        - Matches a closing parenthesis
 * .        - Matches a period (dot)
 *           - Matches a space (the blank space in the character set)
 * ]+       - Matches one or more of the characters in the set
 * $        - Asserts the end of the string
 *
 * Together, this regex ensures that the entire string consists only of
 * characters typically found in phone numbers, including periods, from start to finish.
 */
const validPhoneCharactersRegex = /^[\d+\-(). ]+$/;
const stripPhoneCharacteresRegex = /[\s\-.()+]/g;

function phoneNumberCouldPlausiblyBeValid(phoneNumber: string): boolean {
  if (!phoneNumber || !phoneNumber.length) {
    return false;
  }

  if (!validPhoneCharactersRegex.test(phoneNumber)) {
    return false;
  }

  const phoneNumberWithNonNumericValuesStripped = phoneNumber.replace(
    stripPhoneCharacteresRegex,
    '',
  );

  // According to the E.164, the maximum length is 15
  // See: https://en.wikipedia.org/wiki/E.164
  if (phoneNumberWithNonNumericValuesStripped.length > 15) {
    return false;
  }

  if (phoneNumberWithNonNumericValuesStripped.length < 5) {
    // The minimum length of a phone number in Saint Helena is 5, which I think is probably as short as it gets
    // See: https://en.wikipedia.org/wiki/Telephone_numbers_in_Saint_Helena_and_Tristan_da_Cunha
    return false;
  }

  return true;
}

function hasNonASCIICharacters(value: string): boolean {
  return /[\u0080-\uFFFF]/.test(value);
}

function isValidEmail(value: string): boolean {
  if (value && isEmail(value)) {
    const [localPart] = value.split('@');
    if (!hasNonASCIICharacters(localPart)) {
      return true;
    }
  }
  return false;
}

function validate(key: string, value: string, checker): string | null {
  const check: Validator = {};
  const regex = checker.validator;
  check.alpha = /^[A-z ]+$/;
  check.notJustWhiteSpace = /\S/; // must have at least 1 non-whitespace character
  const trimmedValue = value && value.replace(/\s/g, '');
  if (!value || trimmedValue.length < checker.length) {
    return key;
  }

  if (checker.validator === 'email') {
    return !isValidEmail(value) ? key : null;
  }

  if (checker.validator === 'phone') {
    const isPossible = phoneNumberCouldPlausiblyBeValid(value);
    if (isPossible !== true) {
      return key;
    }

    return null;
  }

  if (!!regex && check[regex].test(value) === false) {
    return key;
  }

  return null;
}

const getListOfInvalidFields = (fields: Address, checks: Object): Array<string> => {
  const valid = Object.keys(checks)
    .map(key => validate(key, fields[key], checks[key]))
    .filter(value => value !== null);

  return valid;
};

const basicAddressFieldRequirements = {
  firstName: {
    length: 1,
    validator: 'notJustWhiteSpace',
  },
  lastName: {
    length: 1,
    validator: 'notJustWhiteSpace',
  },
  address: {
    length: 1,
    validator: 'notJustWhiteSpace',
  },
  city: {
    length: 1,
    validator: 'notJustWhiteSpace',
  },
  state: {
    length: 1,
    validator: 'notJustWhiteSpace',
  },
  postalCode: {
    length: 3,
    validator: 'notJustWhiteSpace',
  },
  country: {
    length: 1,
    validator: 'alpha',
  },
};

const emailValidationRequirements = {
  length: 5, // minimal, eg. a@b.c
  validator: 'email',
};

const extendedAddressFieldRequirements = {
  email: emailValidationRequirements,
  phone: {
    validator: 'phone',
  },
};

export {
  basicAddressFieldRequirements,
  extendedAddressFieldRequirements,
  emailValidationRequirements,
  getListOfInvalidFields,
  hasNonASCIICharacters,
  isValidEmail,
  validate,
};
